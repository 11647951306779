import React from "react"
import {useTranslation} from "react-i18next"

import './about-project.component.less';

import Map from "../../assets/video/about-project/map.mp4"
import MapPoster from "../../assets/img/about-project/img/map-poster.jpg"

import Team from "../../assets/img/about-project/img/team.jpg"
import MinEcoChe from "../../assets/img/about-project/icon/min_eco_che.png"
import MinRes from "../../assets/img/about-project/icon/min_res.jpg"
import LenObl from "../../assets/img/about-project/icon/len_obl_prir.jpg"
import udmurtRepUprav from "../../assets/img/about-project/icon/udmurtRepUprav.jpg"
import Rostelekom from "../../assets/img/about-project/icon/rostelekom.jpg"
import Ertelekom from "../../assets/img/about-project/icon/ertelekom.png"
import Megafon from "../../assets/img/about-project/icon/megafon.png"
import Nornikel from "../../assets/img/about-project/icon/nornikel.jpg"
import Vtu from "../../assets/img/about-project/icon/vtu.jpg"
import Nlmk from "../../assets/img/about-project/icon/nlmk.jpg"
import Rusmed from "../../assets/img/about-project/icon/rus-med.png"
import Sibagro from "../../assets/img/about-project/icon/sibagro.png"
import SEO from "../seo";
import {useI18next} from "gatsby-plugin-react-i18next";


const AboutProjectComponent = () => {
    const {i18n, t} = useTranslation()
    const {originalPath} = useI18next()

    return (
        <div>
            <SEO description={t("metaDescriptionAboutTheProject")}
                 lang={i18n.language} title={t("titleAboutTheProject")}
                 link={"https://cityair.ru/" + i18n.language + originalPath}/>
            <div className="wrapper_container_main align_center_ipad wrapper_container_main-flex">
                <div className="container">
                    <div className="row">
                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-6 col-fhd-6 col-rt-6 flex_box_col flex_box_align_ipad">
                            <div
                                className="color_purple about_project_main_block__title">{t("aboutTheProject")}</div>
                            <div
                                className="color_black about_project_main_block__sub_title">{t("ourGoalIs")}</div>
                            <div
                                className="color_black_light about_project_main_block__description">{t("toDoThisWe")}</div>
                        </div>
                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-6 col-fhd-6 col-rt-6"/>
                    </div>
                </div>
                <div className="about-project__wrapper-video">
                    <video loop autoPlay muted playsInline poster={MapPoster} className="about-project__video" >
                        <source src={Map} type="video/mp4"/>
                    </video>
                </div>
            </div>

            <div className="wrapper_container about__platform-elements">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xga-12 col-fhd-12 col-rt-12">
                            <h2 className="about__platform-elements__title">{t("platformElementsCityAir")}</h2>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xga-12 col-fhd-12 col-rt-12">
                            <div className="about__platform-elements__items-wrapper">
                                <ul className="about__platform-elements__items">
                                    <li className="about__platform-elements__item">{t("elementStation")}</li>
                                    <li className="about__platform-elements__item">{t("elementIntegration")}</li>
                                </ul>
                                <ul className="about__platform-elements__items">
                                    <li className="about__platform-elements__item">{t("elementDataAnalysis")}</li>
                                    <li className="about__platform-elements__item">{t("elementForecasts")}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="wrapper_container wrapper_container-gray">
                <div className="container about_project_block_2__container">
                    <div className="row">
                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-6 col-fhd-6 col-rt-6">
                            <div
                                className="about_project_block_2__title">{t("developmentOfThe")}</div>
                            <div
                                className="about_project_block_2__description color_black_light">{t("fromTheVery")}</div>
                        </div>
                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-6 col-fhd-6 col-rt-6">
                            <img alt="" src={Team} className="img_about_project_team"/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="wrapper_container">
                <div className="container about_project_block_3__container">
                    <div className="row">
                        {i18n.language === 'ru' && <div
                            className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xga-12 col-fhd-12 col-rt-12 about_project_block_3__string-title">
                            {t("cityAirTeamHas")}
                        </div>}
                        {i18n.language === 'en' && <div
                            className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xga-12 col-fhd-12 col-rt-12 about_project_block_3__string-title about_project_block_3__string-title-en">
                            {t("cityAirTeamHas")}
                        </div>}
                    </div>
                    <div className="row color_black_light">
                        <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xga-3 col-fhd-3 col-rt-3 about_project_block_3__string">
                            <div className="about_project_block_3__icon"><img
                                alt="" src={MinEcoChe} className="img_about_project_icon"/>
                            </div>
                            <div
                                className="about_project_block_3__string-icon_name">
                                {t("ministryOfEcologyOf")}
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xga-3 col-fhd-3 col-rt-3 about_project_block_3__string">
                            <div className="about_project_block_3__icon">
                                <img alt="" src={MinRes} className="img_about_project_icon"/>
                            </div>
                            <div
                                className="about_project_block_3__string-icon_name">
                                {t("ministryOfNatural")}
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xga-3 col-fhd-3 col-rt-3 about_project_block_3__string">
                            <div className="about_project_block_3__icon">
                                <img alt="" src={LenObl} className="img_about_project_icon"/>
                            </div>
                            <div className="about_project_block_3__string-icon_name">
                                {t("lenOblAgency")}
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xga-3 col-fhd-3 col-rt-3 about_project_block_3__string">
                            <div className="about_project_block_3__icon">
                                <img alt="" src={udmurtRepUprav} className="img_about_project_icon"/>
                            </div>
                            <div className="about_project_block_3__string-icon_name">
                                {t("ministryUdmurtRepublic")}
                            </div>
                        </div>
                        <div className="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xga-3 col-fhd-3 col-rt-3 about_project_block_3__string about_project_block_3__string_without-text">
                            <div className="about_project_block_3__icon about_project_block_3__icon_without-text">
                                <img alt="" src={Rostelekom} className="img_about_project_icon img_about_project_icon_without-text"/>
                            </div>
                        </div>
                        <div className="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xga-3 col-fhd-3 col-rt-3 about_project_block_3__string about_project_block_3__string_without-text">
                            <div className="about_project_block_3__icon about_project_block_3__icon_without-text">
                                <img alt="" src={Ertelekom} className="img_about_project_icon img_about_project_icon_without-text"/>
                            </div>
                        </div>
                        <div className="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xga-3 col-fhd-3 col-rt-3 about_project_block_3__string about_project_block_3__string_without-text">
                            <div className="about_project_block_3__icon about_project_block_3__icon_without-text">
                                <img alt="" src={Megafon} className="img_about_project_icon img_about_project_icon_without-text"/>
                            </div>
                        </div>
                        <div className="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xga-3 col-fhd-3 col-rt-3 about_project_block_3__string about_project_block_3__string_without-text">
                            <div className="about_project_block_3__icon about_project_block_3__icon_without-text">
                                <img alt="" src={Nornikel} className="img_about_project_icon img_about_project_icon_without-text"/>
                            </div>
                        </div>
                        <div className="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xga-3 col-fhd-3 col-rt-3 about_project_block_3__string about_project_block_3__string_without-text">
                            <div className="about_project_block_3__icon about_project_block_3__icon_without-text">
                                <img alt="" src={Vtu} className="img_about_project_icon img_about_project_icon_without-text"/>
                            </div>
                        </div>
                        <div className="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xga-3 col-fhd-3 col-rt-3 about_project_block_3__string about_project_block_3__string_without-text">
                            <div className="about_project_block_3__icon about_project_block_3__icon_without-text">
                                <img alt="" src={Nlmk} className="img_about_project_icon img_about_project_icon_without-text"/>
                            </div>
                        </div>
                        <div className="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xga-3 col-fhd-3 col-rt-3 about_project_block_3__string about_project_block_3__string_without-text">
                            <div className="about_project_block_3__icon about_project_block_3__icon_without-text">
                                <img alt="" src={Rusmed} className="img_about_project_icon img_about_project_icon_without-text"/>
                            </div>
                        </div>
                        <div className="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xga-3 col-fhd-3 col-rt-3 about_project_block_3__string about_project_block_3__string_without-text">
                            <div className="about_project_block_3__icon about_project_block_3__icon_without-text">
                                <img alt="" src={Sibagro} className="img_about_project_icon img_about_project_icon_without-text"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="wrapper_container wrapper_container-gray">
                <div className="container about_project_block_4__container">
                    <div className="row">
                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xga-12 col-fhd-12 col-rt-12 about_project_block_4__string-title">
                            {t("collaboration")}
                        </div>
                    </div>
                    <div className="row">
                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xga-5 col-fhd-5 col-rt-5">
                            <div
                                className="about_project_block_4__string-sub_title_left">
                                {t("dataFromTheDeployed")}
                            </div>
                            <ul className="color_black_light">
                                <li className="about_project_block_4__li">
                                    {t("analysisOfTheState")}
                                </li>
                                <li className="about_project_block_4__li">
                                    {t("developmentOfNew")}
                                </li>
                                <li className="about_project_block_4__li">
                                    {t("testingAndCalibration")}
                                </li>
                            </ul>
                        </div>
                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-1 col-xl-1 col-xga-1 col-fhd-1 col-rt-1"/>
                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-6 col-fhd-6 col-rt-6">
                            <div
                                className="about_project_block_4__string-sub_title_right">
                                {t("theFollowingEnterprises")}
                            </div>
                            <ol className="color_black_light">
                                <li className="about_project_block_4__li">
                                    {t("veZuev")}
                                </li>
                                <li className="about_project_block_4__li">
                                    {t("voevodskyInstituteOf")}
                                </li>
                                <li className="about_project_block_4__li">{t("federalResearchCenter")}</li>
                                <li className="about_project_block_4__li">{t("novosibirskStateUniversity")}</li>
                                <li className="about_project_block_4__li">{t("skolkovoInstituteOf")}</li>
                                <li className="about_project_block_4__li">{t("southUralState")}</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
}

AboutProjectComponent.propTypes = {}

AboutProjectComponent.defaultProps = {}

export default AboutProjectComponent
